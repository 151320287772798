<!-- @Author: Yu_Bo -->
<template>
    <div class='details_model zc_dialog_box'>
        <el-dialog :visible.sync="dialogVisible" width="635px" :close-on-click-modal='false' @close='closeBtn'>
            <img class="dialog_bg" src="@/assets/images/background/details_background.png" alt="">
            <div class="title" slot="title">
                <div class="name">分身详情</div>
            </div>
            <div class="details_main">
                <div class="main_left">
                    <video ref="videoPlayer" :src="demo_video" controls="controls" autoplay="autoplay" loop="loop"
                        controlslist="nodownload noremoteplayback noplaybackrate" disablepictureinpicture></video>
                </div>
                <div class="main_right">
                    <div class="right_text">
                        <div class="t_name">名称：</div>
                        <div class="t_txt">{{ scene_name }}</div>
                    </div>
                    <div class="right_text">
                        <div class="t_name">比例：</div>
                        <div class="t_txt">{{ ratio == 1 ? '横版16:9' : '竖版9:16' }}</div>
                    </div>
                    <div class="right_text" v-if="model_type">
                        <div class="t_name">类别：</div>
                        <div class="t_tip">
                            <span>{{ model_type }}</span>
                        </div>
                    </div>
                    <div class="right_text">
                        <div class="t_name">时长：</div>
                        <div class="t_txt">{{ video_time }}秒</div>
                    </div>
                    <div class="right_text" v-if="describe">
                        <div class="t_name">简介：</div>
                        <div class="t_txt">{{ describe }}</div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        options: {
            type: Array,
            defaul: () => [],
        }
    },
    data() {
        return {
            scene_name: '',
            ratio: 1,//1横版，2竖版
            model_type: "",
            video_time: '',
            describe: '',
            demo_video: '',
            dialogVisible: false
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开
        openDialog(info) {
            this.scene_name = info.scene_name
            this.ratio = info.ratio
            this.video_time = info.video_time
            this.describe = info.describe
            this.demo_video = info.demo_video
            var obj = this.options.find(item => item.value = info.model_type)
            if (obj) {
                this.model_type = obj.label
            }
            this.dialogVisible = true
        },
        // 关闭
        closeBtn() {
            this.$refs.videoPlayer.pause();//暂停
            // this.$refs.videoPlayer.play();//播放
        },
    },
}
</script>

<style lang='scss' scoped>
.details_model {
    ::v-deep .el-dialog {
        height: 460px;
        background: none
    }

    .dialog_bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        display: block;
        width: 100%;
        height: 460px;
    }

    .details_main {
        box-sizing: border-box;
        width: 100%;
        height: 365px;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;

        .main_left {
            width: 265px;
            height: 100%;

            video {
                display: block;
                width: 100%;
                height: 100%;
            }
        }

        .main_right {
            box-sizing: border-box;
            width: 300px;
            height: 100%;

            .right_text {
                padding-top: 35px;
                display: flex;
                align-items: center;

                .t_name {
                    width: 70px;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }

                .t_txt {
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    display: flex;
                    align-items: center;
                }

                .t_tip {
                    display: flex;
                    align-items: center;

                    span {
                        display: block;
                        padding: 0 15px;
                        height: 26px;
                        line-height: 26px;
                        background: rgba(46, 75, 242, 0.1);
                        border-radius: 13px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #2E4BF2;
                        margin-left: 10px;
                    }

                    span:first-child {
                        margin-left: 0;
                    }
                }
            }

            .right_text:first-child {
                padding-top: 20px;
            }
        }
    }
}
</style>
